// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyASTFAAoZo1vuts8reOWdLlAyVBYWxzx5o',
  authDomain: 'jcb-qr-generator-v2.firebaseapp.com',
  projectId: 'jcb-qr-generator-v2',
  storageBucket: 'jcb-qr-generator-v2.appspot.com',
  messagingSenderId: '214960384110',
  appId: '1:214960384110:web:928583fab725733b405e7f',
  measurementId: 'G-3RD70XHBZD',
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
